<template>
  <v-app id="inspire">
    <v-main class="grey -2">
      <v-container class="mt-16 pt-16 px-6" fluid>
        <v-card
          dark
          class="mx-auto text-center"
          elevation="5"
          max-width="400"
          min-height="200"
        >
          <v-card-title class="text-h5 justify-center">
            <v-img
              max-height="100"
              max-width="200"
              src="@/assets/logo-cheers.svg"
            ></v-img>
          </v-card-title>
          <v-card-text class="px-10">
            <v-btn
              color="primary"
              outlined
              rounded
              small
              class="ma-2 dark--text"
              @click="login"
            >
              <v-icon left dark> mdi-fingerprint </v-icon>
              Login With Azure
            </v-btn>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Login',

  methods: {
    ...mapActions('auth', ['login']),
  },
};
</script>
